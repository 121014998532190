<template>
  <p :class="fieldValidationClasses">
    <i class="fas fa-info-circle"></i>&nbsp;<slot></slot>
  </p>
</template>

<script>
  export default {
    name: 'EmailValidator',
    props: {
      email: {
        type: String,
        required: true
      }
    },
    computed: {
      invalidEmail ( ) {
        const regex = new RegExp( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
        return this.email !== '' && !regex.exec( this.email );
      },
      fieldValidationClasses ( ) {
        return this.invalidEmail ? 'invalid-field' : 'valid-field';
      }
    }
  }
</script>

<style scoped lang="scss">
  .valid-field, .invalid-field {
    font-size: 12px;
    color: #3e3e3e;
    background: #ffe371;
    padding: 5px 0;
    margin: 0;
    cursor: default;
    width: 100%;
    text-align: center;
    transition: 300ms;
  }
  .valid-field {
    font-size: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
  .invalid-field {
    opacity: 1;
  }
</style>