<template>
  <div class="Login">
    <div class="Login__info">
      <h1 v-if="!theme.loginWelcomeTextBlack">{{$t('login_welcome_text')}}</h1>
      <h1 v-if="theme.loginWelcomeTextBlack">{{theme.loginWelcomeTextBlack}}</h1>
      <h2 v-if="!theme.loginWelcomeTextColor && communityInfo.watlab" :style="{ color: theme.mainColorText }">WAT Lab</h2>
      <h2 v-if="!theme.loginWelcomeTextColor && !communityInfo.watlab" :style="{ color: theme.mainColorText }">Experience Lab</h2>
      <h2 v-if="theme.loginWelcomeTextColor" :style="{ color: theme.mainColorText }">{{theme.loginWelcomeTextColor}}</h2>
      <div class="Login__info__text">
      <p>{{theme.loginText}}</p>
      </div>
    </div>
    <div class="Login__form">
      <form>
        <div>
          <label>{{$t('profile_personalization_email')}}</label>
          <input type="text" v-model="formLogin.email" @keydown="clearErrors">
        </div>
        <div>
          <label>{{$t('password')}}</label>
          <input type="password" v-model="formLogin.password" @keydown="clearErrors">
        </div>
        <Button color="accent" :buttonType="'submit'" :disabled="!formValid" @click="handleLogin($event)">
          {{$t('login_access')}}
          <i class="fas fa-users button-icon"></i>
          <Spinner v-show="loadingAuth" />
        </Button>
      </form>
      <router-link :to="{ name: 'resetPassword' }">{{$t('lost_password')}}</router-link>
    </div>
    <form-errors :showErrors="authenticationError">{{$t('login_error')}}</form-errors>
    <email-validator :email="formLogin.email">{{$t('error_need_real_email')}}</email-validator>
    <p style="color: lightgray; padding: 20px 20px 20px 60px;" v-html="theme.initialNotice"></p>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
  import {
    USER_AUTH_LOGIN,
    USER_REMOVE_AUTH_ERROR,
    USER_FETCH_PROFILE
  } from '@/store/user.module';
  import {
    COMMUNITY_FETCH_INFO,
  } from "@/store/community/info.module";
  import { mapGetters } from 'vuex';
  import store from '@/store';
  import Avatar from '~/components/Avatar';
  import Button from '~/components/Button';
  import EmailValidator from '@/components/EmailValidator.vue';
  import FormErrors from '@/components/FormErrors.vue';
  import Spinner from '@/components/Spinner.vue';
  import {
    verifyEmail,
    fetchUserProfile,
  }  from '@/utils';


  export default {
    name: 'LoginPage',

    components: {
      Avatar,
      Button,
      EmailValidator,
      FormErrors,
      Spinner
    },

    data() {
      return {
        formLogin: {
          email: '',
          password: '',
          error: false,
        },
        editForm: {
          nickname  : "",
          name      : "",
          surname1  : "",
          email     : "",
          gender    : "",
          bornDate  : "",
          province  : {},
          picture   : "",
          medals    : { bronce: 0, gold: 0, silver: 0 }
        }
      };
    },

    computed: {
      ...mapGetters([
        'theme',
        'isAuthenticated',
        'nextStep',
        'authenticationError',
        'loadingAuth',
        'communityInfo'
      ]),

      formValid() {
        return (
          verifyEmail( this.formLogin.email ) && this.formLogin.password !== ''
        );
      },
    },
    beforeMount(){
      if(this.$route.params.finishLogin) {
        this.finishLogin()
      }
    },
    methods: {
      async handleLogin(e) {
        e.preventDefault();

        await store.dispatch(USER_AUTH_LOGIN, {
          communityName: this.$route.params.origin,
          loginData: {
            email: this.formLogin.email,
            password: this.formLogin.password,
          },
        });
        if ( this.isAuthenticated ) {
          const origin = this.$route.params.origin;
          if(this.theme.active2fa){
            this.$router.push( { name: "2FA", params: { origin } } );
            return
          }
          else {
            this.finishLogin()
          }
        }
      },
      async finishLogin(){
        const origin = this.$route.params.origin;
        await fetchUserProfile( this );
        if (this.nextStep == "afterLogin") {
          this.$router.push( { name: "completeProfile", params: { origin } } );
        } else {
          if (this.$route.params.redirectTo && this.$route.params.redirectTo.name != undefined && this.$route.params.redirectTo.params != undefined) {
            this.$router.push( { name: this.$route.params.redirectTo.name, params: this.$route.params.redirectTo.params } );
          } else {
            this.$router.push( { name: this.nextStep, params: { origin } } );
          }
        }
        if(this.nextStep == "welcome" && this.theme.afterLoginBypass){
          let url = window.location.pathname.replace('/login','/conditions')
          url = url.replace('/welcome','/conditions')
          let swalhtml = document.createElement('div');
          swalhtml.innerHTML = this.$t('signup_conditions1')+" <a href="+url+" target='_blank'>"+this.$t('signup_conditions2')+"</a>"
          let vm = this
          Swal.fire({
            html: swalhtml,
            icon: "info",
            confirmButtonText: this.$t('msg_agree'),
            customClass:{
              popup:'swal-customInfo'
            }
          })
        }
      },
      clearErrors ( e ) {
        if ( this.authenticationError )
          store.commit( USER_REMOVE_AUTH_ERROR );
      }
    },
  };
</script>

<style lang="scss">
  .Login {
    width                 : 100%;


    @media only screen and ( max-width: 769px ) {
      width               : 100%;
      padding             : 1rem 0;
    }

    &__info {
      width               : 75%;
      display             : flex;
      flex-direction      : column;
      align-items         : flex-start;
      margin-left         : 60px;

      h1 {
        font-size         : 25px;
        font-family       : inherit;
        font-weight       : 700;
        margin-bottom     : 0;
        color             : #191919;
      }

      h2 {
        margin            : 0;
        margin-top        : -10px;
        padding           : 0 0 10px 0;
        border-bottom     : 1px solid #e9e9e9;
        font-size         : 40px;
        font-family       : inherit;
        font-weight       : 700;
      }

      p {
        color             : #797979;
        font-size         : 13px;
      }

      &__text {
        padding-top       : 10px;
        padding-bottom    : 10px;
      }
    }

    &__form {
      padding             : 20px 0 20px 60px;
      background          : #ededed;
      display             : flex;
      flex-direction      : column;
      align-items         : flex-start;

      form {
        display           : flex;
        align-items       : flex-end;
        flex-wrap         : wrap;
        margin            : 5px 0;

        .Button {
          margin-top      : 10px;
        }

        div {
          display         : flex;
          flex-direction  : column;
          margin-right    : 10px;

          label {
            font-size     : 12px;
            color         : #494949;
            font-weight   : bold;
          }

          input {
            padding       : 10px;
            margin-top    : 5px;
            border        : none;
            outline       : none;
            box-shadow    : 0px 0px 20px 0px rgba( 0, 0, 0, 0.1 );
            transition    : box-shadow 300ms;

            &:focus {
              box-shadow  : 0px 0px 20px 0px rgba( 0, 0, 0, 0.3 );
            }
          }
        }
      }
      a {
        color             : var( --text-color );
        font-size         : 12px;

        &:hover {
          text-decoration : underline;
        }
      }
    }

    .button-icon{
      margin: auto auto auto 5px;
      font-size: 18px;
    }
  }

  @media only screen and ( max-width: 769px ) {
    .Login {
      &__info {
        margin            : 1rem auto;
      }
      &__form {
        padding           : 20px;

        form {
          flex-direction  : column;
          width           : 100%;

          .Button--Accent {
            margin-top    : 10px;
            width         : 100%;
            height        : 34px;
            font-size     : 16px;
          }

          div {
            width         : 100%;
            margin-right  : 0;

            input {
              margin      : 5px 0;
            }
          }
        }
      }
    }
  }
</style>
